'use client';

import { twMerge } from 'tailwind-merge';
import { motion, useReducedMotion } from 'motion/react';
import Icon from '@mdi/react';
import EmojiText from '../EmojiText/EmojiText';
import Link from 'next/link';

const TextButton = ({
	label,
	icon,
	title,
	isFlat = false,
	layoutId,
	layoutDependency,
	className,
	onClick,
	href,
}: {
	label: string;
	icon?: string;
	title: string;
	isFlat?: boolean;
	layoutId?: string;
	layoutDependency?: unknown;
	className?: string;
	onClick?: (
		event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
	) => void;
	href?: string;
}) => {
	const reduceMotion = useReducedMotion();

	const ElementTag = !href ? motion.button : motion.div;

	const baseElement = (
		<ElementTag
			layoutId={layoutId}
			layoutDependency={layoutDependency}
			className={twMerge(
				'group relative flex gap-2 max-w-xs py-2 items-center rounded-full border-2 border-black/10 bg-white/80 dark:bg-slate-900/60 text-start md:text-lg cursor-pointer',
				isFlat ? 'shadow-none' : 'shadow-lg',
				!!icon ? 'ps-5 pe-3' : 'px-5',
				className,
			)}
			onClick={onClick}
			whileHover={{
				scale: reduceMotion ? 1 : 1.05,
			}}
			whileTap={{
				scale: reduceMotion ? 1 : 0.9,
			}}
			title={title}
			tabIndex={!href && !!onClick ? 0 : -1}
		>
			<EmojiText>{label}</EmojiText>
			{!!icon && <Icon path={icon} size={1} className="shrink-0" />}
		</ElementTag>
	);

	if (!href) return baseElement;
	else
		return (
			<Link
				href={href}
				passHref
				target={href.startsWith('http') ? '_blank' : undefined}
			>
				{baseElement}
			</Link>
		);
};

export default TextButton;
