/* eslint-disable @next/next/no-img-element */
import { getPortfolioItems } from '@/resources/messages/portfolio/portfolio';
import { useLocale, useTranslations } from 'next-intl';
import { useRouter, useSearchParams } from 'next/navigation';
import { FC, useMemo } from 'react';
import PortfolioTags from './PortfolioTags';
import Link from 'next/link';
import Card from '../Card/Card';
import { twMerge } from 'tailwind-merge';
import { motion, AnimatePresence, useReducedMotion } from 'motion/react';
import { Locale } from '@/i18n/routing';
import TextButton from '../TextButton/TextButton';
import { mdiEye } from '@mdi/js';

const Portfolio: FC<{
	tags?: string[];
	maxItems?: number;
	excludeItemsWithPaths?: string[];
}> = ({ tags = [], maxItems = undefined, excludeItemsWithPaths = [] }) => {
	const locale = useLocale() as Locale;
	const searchParams = useSearchParams();
	const router = useRouter();
	const translations = useTranslations('Portfolio');

	const reduceMotion = useReducedMotion();

	const enabledTags = useMemo(() => {
		const searchParamsTags = searchParams.get('portfolioTags');
		return [...(searchParamsTags ?? '').split(','), ...tags].filter(
			(tag) => tag.length > 0,
		);
	}, [tags, searchParams]);

	const portfolioItems = useMemo(
		() => getPortfolioItems(locale as Locale),
		[locale],
	);

	const filteredPortfolioItems = useMemo(() => {
		if (enabledTags.length === 0)
			return [...portfolioItems.items].slice(0, maxItems);

		// Only show portfolio items that have one of the enabled tags, or has a date with a year that appears in the enabled tags
		const itemsWithTags = portfolioItems.items
			.filter((item) => {
				if (enabledTags.length === 0) return true;

				if (item.tags.some((tag) => enabledTags.includes(tag)))
					return true;

				if (item.date && enabledTags.includes(item.date[0].toString()))
					return true;

				return false;
			})
			// Filter out items with paths that should be excluded
			.filter((item) => !excludeItemsWithPaths.includes(item.path));

		// Sort the items so that the ones with the most matching tags are first
		const itemsWithMatchingTags = itemsWithTags.map((item) => ({
			...item,
			matchingTags: item.tags.filter((tag) => enabledTags.includes(tag)),
		}));

		itemsWithMatchingTags.sort(
			(a, b) => b.matchingTags.length - a.matchingTags.length,
		);

		return itemsWithMatchingTags.slice(0, maxItems);
	}, [enabledTags, maxItems, excludeItemsWithPaths, portfolioItems.items]);

	return (
		<div className="min-h-screen">
			{tags.length < 1 && <PortfolioTags />}
			<ol
				className="grid justify-center gap-x-4 gap-y-8 pt-8"
				style={{
					gridTemplateColumns:
						filteredPortfolioItems.length > 1
							? 'repeat(auto-fill, minmax(20rem, 1fr))'
							: 'min(100%, 28rem)',
				}}
			>
				<AnimatePresence>
					{filteredPortfolioItems.map((item) => {
						const icon = 'icon' in item ? item.icon : null;

						const thumbnail =
							'images' in item &&
							item.images &&
							item.images.length > 0
								? item.images[0]
								: null;
						return (
							<motion.li
								key={`${item.title}-${item.date}`}
								layoutId={item.title}
								layoutDependency={filteredPortfolioItems}
								className={twMerge(
									!!thumbnail
										? 'h-96 row-span-2'
										: 'h-48 -mt-4',
								)}
								tabIndex={-1}
								initial={{
									opacity: 0,
									scale: reduceMotion ? 1 : 0.95,
								}}
								animate={{ opacity: 1, scale: 1 }}
								whileHover={{
									scale: reduceMotion ? 1 : 1.025,
								}}
								whileTap={{
									scale: reduceMotion ? 1 : 0.975,
								}}
								exit={{
									opacity: 0,
									scale: reduceMotion ? 1 : 0.8,
								}}
							>
								<Link
									href={`/${locale}/portfolio/${item.path}`}
									className="group block h-full rounded-3xl"
								>
									<Card className="flex h-full gap-4">
										{!!icon && !thumbnail && (
											<img
												src={icon.src}
												alt={icon.alt}
												className={twMerge(
													'h-10 w-10 shrink-0 rounded-full lg:h-14 lg:w-14',
													'border-2 border-black/10 object-cover',
													'translate-x-1 translate-y-1',
												)}
											/>
										)}
										<div className="flex flex-col gap-2">
											{!!thumbnail && (
												<div
													className={twMerge(
														'relative -mb-6 min-h-0 w-full grow -translate-y-8',
														'motion-safe:group-hover:scale-[1.03] transition-all duration-300',
													)}
												>
													<img
														src={thumbnail.src}
														alt={thumbnail.alt}
														className={twMerge(
															'absolute inset-0 size-full',
															'rounded-3xl object-cover object-top shadow-lg',
														)}
													/>
													{!!icon && (
														<img
															src={icon.src}
															alt={icon.alt}
															className={twMerge(
																'absolute top-2 end-4',
																'h-12 w-12 shrink-0 rounded-full lg:h-16 lg:w-16',
																'border-2 border-black/10 object-cover',
																'translate-x-1 translate-y-1',
															)}
														/>
													)}
												</div>
											)}
											<div className="grid shrink-0 gap-2 px-1">
												<h3
													className={twMerge(
														'text-xl sm:text-2xl font-bold line-clamp-1',
														!thumbnail && 'pt-2',
													)}
												>
													{item.title}
												</h3>
												<p
													className={twMerge(
														'font-medium',
														thumbnail
															? 'line-clamp-3'
															: 'line-clamp-4',
													)}
												>
													{item.description}
												</p>
											</div>
										</div>
									</Card>
								</Link>
							</motion.li>
						);
					})}
				</AnimatePresence>
			</ol>

			{!tags && enabledTags.length > 0 && (
				<div className="pt-8">
					<Card
						className="bg-cornflower-100 dark:bg-cornflower-900/25 mx-auto flex max-w-md flex-col items-center gap-2"
						title={{
							text: translations('ViewAll.title'),
						}}
					>
						<p className="pb-2">
							{translations('ViewAll.description')}{' '}
							{enabledTags.map((tag) => (
								<span key={tag}>#{tag.toLowerCase()}</span>
							))}{' '}
						</p>
						<TextButton
							icon={mdiEye}
							label={translations('ViewAll.button')}
							title={translations('ViewAll.button')}
							onClick={() =>
								router.push('/', {
									scroll: false,
								})
							}
						/>
					</Card>
				</div>
			)}
		</div>
	);
};

export default Portfolio;
