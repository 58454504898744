import { PortfolioItem } from './portfolio';

export const portfolioItemsEN: PortfolioItem[] = [
	{
		title: 'Podwalks CMS',
		path: 'podwalks-cms',
		subtitle: 'Powerful tool for creating podwalks',
		type: 'static',
		description:
			'Not only was innovative augmented reality utilized, but the production process itself was also thoroughly re-imagined for the podwalk series "Het water komt". Previously, podwalks were created using recorded GPS points and audio files that had to be manually added to the app. This approach was cumbersome and made editing walks time-consuming.\nTime for a change! That\'s why I designed and developed the Podwalks CMS for NTR. This CMS is live-connected to the app for "Het water komt," enabling real-time updates to podwalks. Routes can be visually mapped out on an interactive map, and with the simulation feature, you can test whether the timing and flow between audio points are seamless.\nAdditionally, the CMS offers a range of advanced features, including permissions management, multiple types of triggers on location, built-in API documentation for developers, analytics, route backups, and the ability to upload app-specific files. These functionalities make it not only user-friendly but also flexible and future-proof.',
		images: [
			{
				src: '/assets/portfolio/podwalks-cms-2.webp',
				alt: 'A map displaying a route with points that trigger audio. These points can be edited here.',
			},
			{
				src: '/assets/portfolio/podwalks-cms-1.webp',
				alt: 'A map of the Netherlands showing various locations with podwalks. From here, you can create a new podwalk or edit an existing one.',
			},
			{
				src: '/assets/portfolio/podwalks-cms-3.webp',
				alt: 'A screen for editing the transportation options for a route. Parking spots and public transport stops can be added, as well as the address and an optional warning.',
			},
			{
				src: '/assets/portfolio/podwalks-cms-4.webp',
				alt: 'An interface for writing the story accompanying the audio. All chapters can be selected, and the selected chapter is edited in the center panel.',
			},
		],
		date: [2023, 9, 1],
		tags: ['Podwalk', 'CMS', 'NTR', 'Laravel'],
		links: [
			{
				title: 'Het water komt (app)',
				url: 'https://ntr.nl/site/tekst/Het-water-komt---podwalks/131',
				qr: {
					isEnabled: true,
				},
			},
		],
	},
	{
		title: '"Het water komt" prototype',
		path: 'het-water-komt-prototype',
		subtitle: 'A podwalk with AR',
		type: 'static',
		description:
			'For the upcoming series "Het water komt," the innovation team of NTR wanted to investigate whether a podwalk in combination with AR would be a good match. To validate this, I developed a prototype in two weeks. With this prototype, a podwalk could be walked in Marken, and there were AR points where a 3D model was shown on location. By testing this prototype with the target audience, we could quickly gain insights into the potential of this combination.',
		images: [
			{
				src: '/assets/portfolio/hwk-prototype-1.webp',
				alt: 'People walk a podwalk in Marken towards a bridge. There are many green houses with orange roofs.',
			},
			{
				src: '/assets/portfolio/hwk-prototype-2.webp',
				alt: 'People walk a podwalk in Marken along the quay with boats, next to which are green houses with white fences.',
			},
		],
		date: [2023, 7, 1],
		tags: [
			'Podwalk',
			'Augmented Reality',
			'NTR',
			'Android',
			'iOS',
			'Flutter',
		],
		links: [
			{
				title: 'Het water komt (app)',
				url: 'https://ntr.nl/site/tekst/Het-water-komt---podwalks/131',
				qr: {
					isEnabled: true,
				},
			},
		],
	},
	{
		title: 'Inclusief Design at NTR',
		path: 'inclusiefdesign',
		subtitle: 'Made for everyone, by everyone',
		type: 'static',
		description:
			'Everyone should be able to participate. And to create a place where that is true, everyone is needed.\nInclusive design refers to media design that everyone can perceive and understand, including people with disabilities. Recognizing that there’s still a need for greater focus on inclusive design within public broadcasting—and acknowledging that I can’t solve everything from my role—I developed this site.\nOn Inclusief Design, anyone can explore how to contribute to accessible media from their own role. Whether you are an editor, UX designer, graphic designer, developer, project manager, or innovator, this platform provides guidance.',
		images: [
			{
				src: '/assets/portfolio/inclusiefdesign-1.webp',
				alt: 'Inclusief Design homepage. At the top are links to various roles, such as project manager, editor, and developer. At the bottom, the latest news on inclusive design, like Neurodiversity Pride Day.',
			},
			{
				src: '/assets/portfolio/inclusiefdesign-2.webp',
				alt: 'Pages full of theory for editors. This is an introductory page with a checklist: "Before You Begin." On the side, there are numerous pages covering various topics for editors, such as alt text and subtitles.',
			},
			{
				src: '/assets/portfolio/inclusiefdesign-3.webp',
				alt: 'A contrast checker on the site. You input a foreground and background color and receive a score and minimum size for each font weight. This helps you determine whether and how the colors can be used.',
			},
			{
				src: '/assets/portfolio/inclusiefdesign-4.webp',
				alt: '"Getting Started" page for innovators. A page outlining how to set up the initial brainstorming session and everything needed to begin.',
			},
		],
		date: [2023, 5, 1],
		tags: ['Accessibility', 'NTR'],
		links: [
			{
				title: 'Inclusief Design at NTR',
				url: 'https://inclusiefdesign.ntr.nl',
				qr: {
					isEnabled: true,
				},
			},
		],
	},
	{
		title: 'SoortSafari prototype',
		path: 'soortsafari-prototype',
		subtitle: 'Learning about nature around you through play',
		type: 'static',
		description:
			"Het Klokhuis sought an engaging way to teach children about nature around them. The concept of SoortSafari emerged from my earlier research at NTR and drew inspiration from Het Klokhuis' Dierenzoeker, which allowed users to identify animals they had discovered.\nWith SoortSafari, the approach is reversed: the app provides users with assignments that send them into nature. The prototype featured a calendar and a quartet-style overview of plant and animal species. To complete assignments, users take photos, and AI technology determines if the correct species has been identified. This allows children to build their own collections and learn about nature in a playful, hands-on way.\nThe prototype was met with great enthusiasm. Children engaged with it for more than 45 minutes and even climbed on top of each other to capture better photos of a pigeon perched in a tree. Based on these observations and insights, we began developing a production-ready version to expand on this promising concept.",
		images: [
			{
				src: '/assets/portfolio/soortsafari-prototype-1.webp',
				alt: 'A child takes a photo of a worm and the photo is scanned.',
			},
			{
				src: '/assets/portfolio/soortsafari-prototype-2.webp',
				alt: 'Two children try to photograph species. There is a phone in the picture with quartets of the species. Some have a photo made by the child on the quartet card and are therefore collected.',
			},
			{
				src: '/assets/portfolio/soortsafari-prototype-3.webp',
				alt: 'Children climb on top of each other to be able to take a photo of a bird in a tree.',
			},
		],
		date: [2023, 2, 1],
		tags: [
			'AI',
			'Android',
			'iOS',
			'Website',
			'Education',
			'NTR',
			'Het Klokhuis',
			'React',
		],
		links: [
			{
				title: 'SoortSafari',
				url: 'https://hetklokhuis.nl/dossier/169/soortsafari',
				qr: {
					isEnabled: true,
				},
			},
			{
				title: 'Dierenzoeker',
				url: 'https://www.dierenzoeker.nl/',
			},
		],
	},
	{
		title: 'Klokhuis AI Studio prototype',
		path: 'klokhuis-ai-studio',
		subtitle: 'Learning about AI through play',
		type: 'static',
		description:
			"AI is something that is increasingly common and has more and more influence on our daily lives, but most people still do not fully understand what it is. For my graduation project, I developed this AI Studio where children learn about AI and train their own AI. I wanted to explore how we could give the next generation more control over the subject of artificial intelligence through playful learning. To that end, I investigated an interactive dialogue as a medium, in which the various characters of Het Klokhuis guide you through the theory. With this research, I graduated with two 9.3s and a 9.4. The average grade of my bachelor's courses is an 8.7.",
		grade: '9.3',
		images: [
			{
				src: '/assets/portfolio/klokhuis-ai-1.webp',
				alt: 'A screenshot of the AI Machine where you can train an AI that recognizes images. This AI is trained with hand gestures.',
			},
			{
				src: '/assets/portfolio/klokhuis-ai-2.webp',
				alt: 'Several children enjoying playing with the website.',
			},
			{
				src: '/assets/portfolio/klokhuis-ai-3.webp',
				alt: 'Several children enjoying playing with the website.',
			},
		],
		date: [2022, 7, 1],
		tags: [
			'AI',
			'Educational',
			'Website',
			'Het Klokhuis',
			'React',
			'Laravel',
		],
		links: [
			{
				title: 'Klokhuis AI Studio Prototype',
				url: 'https://www.hetklokhuis.nl/aiprototype',
			},
		],
	},
	{
		title: 'Schooltv Voice Actions',
		path: 'schooltv-voice-actions',
		subtitle: 'Talk with historical figures',
		type: 'static',
		description:
			'I helped Schooltv with new designs for voice actions to make them even more user-friendly. With the voice actions, students can talk to various historical figures, both via speech and typing.',
		images: [
			{
				src: '/assets/portfolio/schooltv-voice-1.webp',
				alt: 'Screenshot of a phone with a conversation with William of Orange. On the screen, you see the text of what he says. You can respond by typing or with your voice.',
			},
			{
				src: '/assets/portfolio/schooltv-voice-2.webp',
				alt: 'Screenshot of a laptop with a conversation with William of Orange. On the screen, you see the text of what he says. You can respond by typing or with your voice.',
			},
		],
		date: [2022, 5, 1],
		tags: ['Voice', 'Schooltv', 'NTR'],
		links: [
			{
				title: 'NPO Innovatie',
				url: 'https://npo.nl/innovatie/projecten/ga-in-gesprek-met-willem-van-oranje#download-validatie-vervolg-schoolttv-voice-action',
			},
			{
				title: 'Schooltv prototype',
				url: 'https://schooltv.nl/link/ga-in-gesprek-met-willem-van-oranje-de-vader-des-vaderlands/',
			},
		],
	},
	{
		title: 'Klokhuis Scanner',
		path: 'klokhuis-scanner',
		subtitle: 'Can you collect them all?',
		type: 'static',
		description:
			'Did you know that you have trillions of invisible pets in your house? But who are they? Can they stay? For NPO Innovatie, I conducted research into using WebAR to give stories for the youth more impact. With the Klokhuis Scanner, you can meet 10 of your invisible pets.',
		grade: '10',
		icon: {
			src: '/assets/portfolio/klokhuis-scanner.webp',
			alt: 'An apple with a stripe of light from a scanner over it',
		},
		images: [
			{
				src: '/assets/portfolio/klokhuisscanner-beerdiertje.webp',
				alt: 'A phone with a camera that recognizes a plant. Here appears the tardigrade.',
			},
			{
				src: '/assets/portfolio/klokhuisscanner-giardia.webp',
				alt: 'A phone with a camera that recognizes a cat. Here appears the giardia.',
			},
			{
				src: '/assets/portfolio/klokhuisscanner-huidbacterie.webp',
				alt: 'A phone with a camera that recognizes a human. Here appears the skin bacteria.',
			},
			{
				src: '/assets/portfolio/klokhuisscanner-huisstofmijt.webp',
				alt: 'A phone with a camera that recognizes a couch. Here appears the dust mite.',
			},
			{
				src: '/assets/portfolio/klokhuisscanner-penseelschimmel.webp',
				alt: 'A phone with a camera that recognizes an apple. Here appears the penicillium mold.',
			},
		],
		date: [2021, 10, 1],
		tags: [
			'Augmented Reality',
			'Educational',
			'Website',
			'Het Klokhuis',
			'React',
			'AI',
		],
		links: [
			{
				title: 'Klokhuis Scanner',
				url: 'https://www.hetklokhuis.nl/klokhuisscanner',
			},
			{
				title: 'NPO Innovatie',
				url: 'https://npo.nl/innovatie/projecten/onzichtbare-huisdieren',
			},
			{
				title: 'LinkedIn post',
				url: 'https://www.linkedin.com/feed/update/urn:li:activity:6850726256124669952/',
			},
		],
	},
	{
		title: 'Telescope Rijksmuseum Boerhaave',
		path: 'verrekijker-rijksmuseum-boerhaave',
		subtitle: 'Rijksmuseum Boerhaave in Leiden',
		type: 'static',
		description:
			'With this prototype, we explored the possibilities of AR in a playground object for children. Through the telescope, you see an extra layer over the courtyard. You can interact with these elements using the lever under the viewer. This study project was developed together with Maisie Klaassen, Marlène Zwetsloot, and Irene de Koning.',
		grade: '8.9',
		icon: {
			src: '/assets/portfolio/boerhaave.webp',
			alt: 'Logo of Museum Boerhaave: A large letter B',
		},
		images: [
			{
				src: '/assets/portfolio/verrekijker-1.webp',
				alt: 'The playground of the museum in the courtyard. There is an augmented reality viewer through which you can see an extra layer. The viewer has a rotating lever.',
			},
			{
				src: '/assets/portfolio/verrekijker-2.webp',
				alt: 'The courtyard with a digital layer over it. Archimedes says: "I lived more than 2000 years ago" and stands next to Archimedes\' screw.',
			},
			{
				src: '/assets/portfolio/verrekijker-3.webp',
				alt: 'The courtyard with a digital layer over it. Here, you can create your own family crest by making different choices.',
			},
		],
		date: [2022, 1, 1],
		tags: ['Augmented Reality', 'Educational', 'Hardware', 'Godot'],
		links: [
			{
				title: 'Waterland Boerhaave',
				url: 'https://rijksmuseumboerhaave.nl/te-zien-te-doen/waterland-boerhaave/',
			},
		],
	},
	{
		title: 'NPO Kennis Accessibility',
		path: 'npo-kennis-toegankelijkheid',
		subtitle: 'NPO Kennis... And you know it!',
		type: 'static',
		description:
			'At NTR, I help to improve the accessibility of components on the NPO Kennis website. I analyze bottlenecks and implement solutions that help make the website accessible to people with disabilities, such as visual impairments.',
		icon: {
			src: '/assets/portfolio/npokennis.webp',
			alt: 'Logo of NPO Kennis with the words NPO and Kennis in diamond shapes.',
		},
		date: [2021, 10, 1],
		tags: ['React', 'Website', 'Accessibility'],
		links: [
			{
				title: 'NPO Kennis',
				url: 'https://www.npokennis.nl/',
			},
		],
	},
	{
		title: "Saibo's Lab 2",
		path: 'saibos-lab-2',
		subtitle: 'Your cheerful virtual teacher!',
		type: 'static',
		description:
			'Saibo is the super helpful virtual teacher who helps you with the rules for lab safety. To enrich education, we developed a virtual reality app for the Biology and Medical Laboratory Research program on behalf of Hogeschool Leiden. The app will also be used by mboRijnland and secondary schools. This app was developed in collaboration with Marlène Zwetsloot and Kim Hoogland.',
		icon: {
			src: '/assets/portfolio/saibo.webp',
			alt: 'Saibo is a floating cute sphere with eyes',
		},
		images: [
			{
				src: '/assets/portfolio/labveiligheid-1.webp',
				alt: 'Saibo welcomes you to the lesson about biological waste processing. Saibo is floating next to a whiteboard, and in front of you is a lab table.',
			},
			{
				src: '/assets/portfolio/labveiligheid-2.webp',
				alt: 'Choice of different lessons: an introductory lesson and various waste processing lessons',
			},
			{
				src: '/assets/portfolio/labveiligheid-3.webp',
				alt: 'Various waste containers are next to the table in the lab.',
			},
			{
				src: '/assets/portfolio/labveiligheid-4.webp',
				alt: 'Next to the waste containers in the lab hangs a diagram showing how to dispose of liquid waste.',
			},
		],
		date: [2021, 8, 1],
		tags: [
			'Virtual Reality',
			'Educational',
			'Website',
			'Android',
			'iOS',
			'Godot',
		],
		links: [
			{
				title: 'Labveiligheid.nl',
				url: 'https://www.labveiligheid.nl/',
			},
			{
				title: 'Play Store',
				url: 'https://play.google.com/store/apps/details?id=nl.labveiligheid',
			},
			{
				title: 'App Store',
				url: 'https://apps.apple.com/nl/app/labveiligheid/id1588125934',
			},
		],
	},
	{
		title: 'MotionSynth',
		path: 'motionsynth',
		type: 'static',
		description:
			'Make music with your hands! MotionSynth is an interactive experiment where you make music by moving your hands. Try it out with your webcam!',
		icon: {
			src: '/assets/portfolio/motionsynth-icon.webp',
			alt: 'A circle with different colors blending into each other: pink, blue, and green',
		},
		images: [
			{
				src: '/assets/portfolio/motionsynth.gif',
				alt: 'I move my hands, causing colored spheres to appear where my hands are.',
			},
		],
		date: [2020, 11, 1],
		tags: ['Website', 'AI', 'Music'],
		links: [
			{
				title: 'MotionSynth',
				url: 'https://motionsynth.web.app/',
			},
		],
	},
	{
		title: 'Gloves',
		path: 'gloves',
		type: 'static',
		description:
			'During this project, Marlène Zwetsloot and I developed prototype gloves that could be used as an instrument. With AI, custom gestures could be trained that could then be connected to any music program.',
		grade: '8.1',
		images: [
			{
				src: '/assets/portfolio/gloves-2.gif',
				alt: 'A glove with wires and chips on it. In the background is a laptop with a music program open.',
			},
			{
				src: '/assets/portfolio/gloves-3.webp',
				alt: 'Various actions of the gloves, such as rotating your index finger, which are connected to musical effects.',
			},
			{
				src: '/assets/portfolio/gloves-1.webp',
				alt: 'The glove up close. Each finger has a flex sensor, and there is a motion sensor in the middle.',
			},
		],
		date: [2020, 11, 1],
		tags: ['Hardware', 'Music', 'AI', 'Flutter'],
		links: [],
	},
	{
		title: 'Laze',
		path: 'laze',
		subtitle: 'Relaxing is important!',
		type: 'static',
		description:
			"Laze helps you with your productivity cycle. Relaxing is important, that's why Laze can also help remind you to stop working on time in the evenings. Made together with Maisie Klaassen as a school project.",
		grade: '8.3',
		icon: {
			src: '/assets/portfolio/laze-icon.webp',
			alt: 'White circle on purple background',
		},
		images: [
			{
				src: '/assets/portfolio/laze-1.webp',
				alt: 'Someone looks happily at a phone. Laze says: "Awesome!"',
			},
			{
				src: '/assets/portfolio/laze-2.webp',
				alt: 'Someone sits behind a laptop. The timer on the phone says 17 minutes of productive time left.',
			},
			{
				src: '/assets/portfolio/laze-3.webp',
				alt: 'The Laze logo with the text: "Hi, I\'m Laze."',
			},
		],
		date: [2020, 8, 1],
		tags: ['Android'],
		links: [
			{
				title: 'Laze',
				url: 'https://play.google.com/store/apps/details?id=net.collaby.laze',
			},
		],
	},
	{
		title: 'Manta',
		path: 'manta',
		type: 'interactive',
		description: 'An abstract shape moves inspired by the manta ray.',
		date: [2020, 8, 1],
		icon: {
			src: '/assets/portfolio/manta.webp',
			alt: 'Two floating abstract shapes in green and purple.',
		},
		tags: ['Creative Coding', 'Website'],
		links: [
			{
				title: 'Manta',
				url: '/assets/art/manta/index.html',
			},
		],
	},
	{
		title: 'Tonest',
		path: 'tonest',
		type: 'static',
		description:
			'Tonest helps you practice singing in tune or tuning your instrument. Tonest is made in Flutter and can be downloaded on Google Play. The app comes with a history graph and built-in piano (with all octaves).',
		images: [
			{
				src: '/assets/portfolio/tonest-1.webp',
				alt: 'Screenshots of the app showing which note is heard and how in-tune it is. There is a graph with the history of the notes and a piano to produce sounds.',
			},
			{
				src: '/assets/portfolio/tonest-2.webp',
				alt: 'The Tonest logo is the word spelled out with stripes behind it.',
			},
			{
				src: '/assets/portfolio/tonest-3.webp',
				alt: 'The simple Tonest logo is just the letter T with stripes behind it.',
			},
		],
		date: [2020, 9, 1],
		tags: ['Flutter', 'Android', 'Music'],
		links: [
			{
				title: 'Play Store',
				url: 'https://play.google.com/store/apps/details?id=net.collaby.tonest',
			},
		],
	},
	{
		title: 'PLNT Tiny Offices',
		path: 'plnt-tiny-offices',
		type: 'static',
		description:
			'With this project for TheField, customers can easily reserve a Tiny Office online. The offices are user-friendly to manage for TheField. Made with Laravel and React. Developed as a school project commissioned by TheField.',
		grade: '10',
		images: [
			{
				src: '/assets/portfolio/thefield-1.webp',
				alt: 'Website with detail page about room Lockerroom. There are photos, and you can reserve the room.',
			},
			{
				src: '/assets/portfolio/thefield-2.webp',
				alt: 'Overview of all rooms on the site with prices, reviews, and information.',
			},
			{
				src: '/assets/portfolio/thefield-3.webp',
				alt: 'Website with detail page about room Fri-Dom. There are photos, and you can reserve the room.',
			},
		],
		date: [2020, 7, 1],
		tags: ['Laravel', 'React', 'Website'],
		links: [
			{
				title: 'Demo',
				url: 'https://thefield.collaby.net/',
			},
		],
	},
	{
		title: 'Karipuna',
		path: 'karipuna',
		subtitle: 'Based on a true story',
		type: 'static',
		description:
			'Experience the atmosphere of this part of the Amazon rain forest while learning about the situation and influencing the course of the story yourself. Accompany André in the decisions he has to make to save his people. An interactive news story for a study project.',
		grade: '8',
		images: [
			{
				src: '/assets/portfolio/karipuna-1.webp',
				alt: 'Screenshot of the site with the title André Karipuna (Chapter 1). You have to click to read the story.',
			},
			{
				src: '/assets/portfolio/karipuna-2.webp',
				alt: 'Screenshot of the site at the end of the story. There is current information with photos of the area and a call-to-action to sign a petition.',
			},
		],
		date: [2019, 11, 1],
		tags: ['Educational', 'Website'],
		links: [
			{
				title: 'Experience the story',
				url: 'https://karipuna.web.app/',
			},
		],
	},
	{
		title: 'Flock',
		path: 'flock',
		type: 'interactive',
		description:
			'Interactive experiment. Type in the field to start and drag with your mouse. Your creation lives and moves like a flock of birds in nature.',
		icon: {
			src: '/assets/portfolio/flock.webp',
			alt: 'The word "Flock" multiple times flying together like a flock of birds.',
		},
		date: [2020, 8, 1],
		tags: ['Creative Coding', 'Website'],
		links: [
			{
				title: 'Flocking text',
				url: '/assets/art/type/index.html',
			},
		],
	},
	{
		title: 'Dinoh',
		path: 'dinoh',
		type: 'static',
		description:
			'Play family games on the TV! Use your Android phone to participate and connect with your Chromecast.',
		images: [
			{
				src: '/assets/portfolio/dinoh-1.webp',
				alt: 'Phones and TV. On TV, a drawing is visible. One phone is drawing, and the other phone has to guess what it is. It looks like a bear.',
			},
			{
				src: '/assets/portfolio/dinoh-2.webp',
				alt: 'Phones and TV. On TV, cars are visible that have to drive a distance. On phones, players have to press the right buttons to move the car forward.',
			},
			{
				src: '/assets/portfolio/dinoh-3.webp',
				alt: 'Phones and TV. On the TV, an animal slowly becomes visible. Players have to guess which animal it is.',
			},
		],
		date: [2018, 11, 1],
		tags: ['Chromecast', 'Android'],
		links: [
			{
				title: 'Play Store',
				url: 'https://play.google.com/store/apps/details?id=nl.birdssoftware.dinoh',
			},
		],
	},
	{
		title: 'Mondriaan',
		path: 'mondriaan',
		type: 'interactive',
		description: 'Go to the site and click to generate a unique Mondrian.',
		icon: {
			src: '/assets/portfolio/mondriaan.webp',
			alt: 'Mondrian picture: straight planes in white, red, blue, and yellow.',
		},
		date: [2020, 8, 1],
		tags: ['Creative Coding', 'Website'],
		links: [
			{
				title: 'Mondriaan',
				url: '/assets/art/mondriaan/index.html',
			},
		],
	},
	{
		title: "Saibo's Lab 1",
		path: 'saibos-lab-1',
		type: 'static',
		description:
			'A virtual reality application for the Biology and Medical Laboratory Research program. Developed with Marlène Zwetsloot and Kim Hoogland as a study project.',
		grade: '9.1',
		images: [
			{
				src: '/assets/portfolio/saibo-1.webp',
				alt: 'A lab table in a classroom. A virtual assistant is teaching.',
			},
			{
				src: '/assets/portfolio/saibo-2.webp',
				alt: 'Settings of the VR app. You can turn the sound on and off, but also the mustache of the virtual assistant.',
			},
		],
		date: [2020, 2, 1],
		tags: ['Virtual Reality', 'Educational', 'Website'],
		links: [
			{
				title: 'Prototype',
				url: 'https://saibolab.web.app/',
			},
		],
	},
	{
		title: 'VR Pixel Paint',
		path: 'vr-pixel-paint',
		type: 'static',
		description:
			'A virtual reality app made with A-Frame. Works on Google Cardboard. You can make pixel art and even mix colors!',
		images: [
			{
				src: '/assets/portfolio/vrdraw-1.webp',
				alt: 'A VR canvas to color with squares.',
			},
			{
				src: '/assets/portfolio/vrdraw-2.webp',
				alt: 'A colored canvas with neat vertical stripes in rainbow colors.',
			},
			{
				src: '/assets/portfolio/vrdraw-3.webp',
				alt: 'A mix option. You put two colors into a funnel, from which a new color comes out.',
			},
		],
		date: [2019, 11, 1],
		tags: ['Virtual Reality', 'Website'],
		links: [
			{
				title: 'Try it out',
				url: 'https://tijmen-vr.web.app/draw/',
			},
		],
	},
	{
		title: 'VR UFO',
		path: 'vr-ufo',
		type: 'static',
		description:
			'Land a UFO in Virtual Reality. Made in A-Frame for Google Cardboard.',
		images: [
			{
				src: '/assets/portfolio/ufo-1.webp',
				alt: 'A controller, planet, and UFO. The controller says: "Pull lever"',
			},
			{
				src: '/assets/portfolio/ufo-2.webp',
				alt: 'The UFO flies away from the planet.',
			},
			{
				src: '/assets/portfolio/ufo-3.webp',
				alt: 'The UFO has landed on the planet.',
			},
		],
		date: [2019, 11, 1],
		tags: ['Virtual Reality'],
		links: [
			{
				title: 'Try it out',
				url: 'https://tijmen-vr.web.app/ufo/',
			},
		],
	},
	{
		title: 'MuseBlocks',
		path: 'museblocks',
		type: 'static',
		description:
			'Everyone can make music with the blocks in MuseBlocks. Made as a school project but never published. Contact me to learn more!',
		icon: {
			src: '/assets/portfolio/museblocks-icon.webp',
			alt: 'Music notes',
		},
		date: [2019, 11, 1],
		tags: ['Flutter', 'Music'],
		links: [],
	},
	{
		title: 'Roasted Mirchi',
		path: 'roasted-mirchi',
		type: 'static',
		description:
			'The website for production house Roasted Mirchi from Mumbai. Roasted Mirchi photographs and also produces music.',
		images: [
			{
				src: '/assets/portfolio/roasted-mirchi.webp',
				alt: 'Website with a large red circle with links alongside it. Links to films, talent, studios, and more.',
			},
			{
				src: '/assets/portfolio/roasted-mirchi-2.webp',
				alt: 'Website with a large red circle with links alongside it. Links to films, talent, studios, and more.',
			},
		],
		date: [2019, 5, 1],
		tags: ['Website'],
		links: [
			{
				title: 'Website',
				url: 'http://roastedmirchi.com/',
			},
		],
	},
	{
		title: 'Ethereal',
		path: 'ethereal',
		type: 'static',
		description:
			'Watch faces for Wear OS smartwatches. No longer available in the Play Store.',
		images: [
			{
				src: '/assets/portfolio/ethereal-1.webp',
				alt: 'Short hands with a large "10" displayed indicating the hour. The battery percentage is also shown. In different colors.',
			},
			{
				src: '/assets/portfolio/ethereal-2.webp',
				alt: 'Minimalistic analog hands. The short hand shines a light on the hour, which is also indicated there with a number. In different colors.',
			},
			{
				src: '/assets/portfolio/ethereal-3.webp',
				alt: 'Three different minimalistic analog clocks with green accent colors.',
			},
			{
				src: '/assets/portfolio/ethereal-4.webp',
				alt: 'Digital clocks with various photos of flowers. The time is displayed in large numbers over it.',
			},
			{
				src: '/assets/portfolio/ethereal-5.webp',
				alt: 'Multiple abstract watch faces. One looks like a universe. Two have large shapes that move with the time. Another has an analog clock, but it is half cut off.',
			},
		],
		date: [2018, 6, 1],
		tags: ['Wear OS', 'Android'],
		links: [],
	},
];
